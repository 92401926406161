// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-covid-19-response-js": () => import("./../../../src/pages/covid-19-response.js" /* webpackChunkName: "component---src-pages-covid-19-response-js" */),
  "component---src-pages-economic-contribution-js": () => import("./../../../src/pages/economic-contribution.js" /* webpackChunkName: "component---src-pages-economic-contribution-js" */),
  "component---src-pages-foreword-js": () => import("./../../../src/pages/foreword.js" /* webpackChunkName: "component---src-pages-foreword-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-landsec-contribution-overview-js": () => import("./../../../src/pages/landsec-contribution-overview.js" /* webpackChunkName: "component---src-pages-landsec-contribution-overview-js" */),
  "component---src-pages-social-sustainability-js": () => import("./../../../src/pages/social-sustainability.js" /* webpackChunkName: "component---src-pages-social-sustainability-js" */),
  "component---src-pages-sustainability-js": () => import("./../../../src/pages/sustainability.js" /* webpackChunkName: "component---src-pages-sustainability-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

